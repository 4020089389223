<template>
  <div class="bu-ju-3 bg-aaa">
    <div class="top">
      <nav-header title="常见问题"></nav-header>
    </div>
    <div class="body">
      <div class="page-content">
        <div class="dao-hang">
          <zi-xun-header />
        </div>
        <div class="lie-biao">
          <van-list style="padding-top: 0;border:0">
            <van-cell
                is-link
                v-for="(item,index) in dataList"
                :class="{ 'list_title_active': item.id == parseInt(id) }"
                @click="tiaoZHuan(item.id)"

                :title="item.name"
            />
          </van-list>
          <div style="padding: 10px 16px;" v-html="detailHtml">

          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <footer-nav/>
    </div>
  </div>
</template>


<script>
//服务大厅
import NavHeader from '@/components/top/NavHeader'
import FooterNav from "../open/footerNav";
import http from "../../api/http";
import _ from 'lodash'
import ZiXunHeader from "@/views/platform/module/ZiXunHeader";

export default {
  name: 'GeneralProblem',
  components: {
    ZiXunHeader,
    NavHeader,
    FooterNav,
  },
  data() {
    return {
      dataList: [],
      xiaLa: null,
      pageName: 'PlatformGeneralProblem',
      activeNames: 0,
      detailInfo: [], //详情数据
      lastId: 0, // 上次id
      detailHtml: '', // 常见问题详情
      activeIndex: 0,
      id: undefined //文章id
    }
  },
  mounted() {
    this.id = _.toInteger(_.get(this.$route, 'query.id'))
    this.getLoad();
  },
  methods: {
    // 获取数据
    getLoad() {
      http.get('/gzhphp/chang_jian_wen_ti/getList').then(res => {
        if (res.data && res.data.data.length > 0) {
          this.dataList = res.data.data;
          this.getDetails()
        }
      })
    },
    // // 获取详情数据
    getDetails() {
      if (!this.id) {
        this.id = _.get(this.dataList, '0.id')
      }

      http.get('/gzhphp/chang_jian_wen_ti/getInfo', {params: {id: this.id}}).then(res => {
        if (res.code === 1) {
          this.detailHtml = res.data.content;
        }
      })
    },
    // //切换页面
    pageChange(page) {
      this.$router.push({name: page})
    },
    tiaoZHuan(id) {
      this.$router.push({name: 'PlatformGeneralProblem', query: {id}})
      this.id = id
      this.getDetails()
    }
  },
  watch: {
    // '$route':{
    //   handler(){
    //     this.id =  _.toInteger(_.get(this.$route,'query.id'))
    //     this.getDetails()
    //   },
    //   deep: true,
    // }
  }
}
</script>

<style scoped lang="less">
.page-content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .lie-biao {
    overflow-y: auto;
    flex: 1;
  }
}

.lie-biao {
  background-color: white;

  ::v-deep .list_title_active,
  ::v-deep .list_title_active i {
    /* color: #4A4096;
     font-weight: 600;*/
    color: rgb(227, 108, 9);
  }
}

</style>
